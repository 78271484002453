body{
  margin: 0;
  padding: 0;
  /* height: 100%;
  width: 100%; */
  background: radial-gradient(circle at bottom right, #f39b51, #de3f81, #7f28c4);
  overflow: auto;
}
/* 
#root{
  width: 100%;
  height: 100%;
} */

.App {
  background: radial-gradient(circle at top right, #f39b51, #de3f81, #7f28c4);
  height : 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  z-index: 10;
  font-style: normal;
  opacity: 1;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  @media (max-width: 650px) {
    width: 95%;
    padding: 1rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.upside{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header{
  max-width: 100%;
  height: auto;
  flex-direction: row;
  display: flex;
  padding: .5rem;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  @media (max-width: 650px) {
    padding: 0;
  }
}

 .logo{
  width: 50%;
  height: 100%;
}

.logo img{
  padding-top: .25rem;
  padding-bottom: .25rem;
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.language{
  color: white;
  display: flex;
  align-items: center;
  border: 1px solid #000;
  border-radius: 9999px;
  padding-top: 0.1rem;
  padding-bottom: 0.15rem;
  transition: all 0.5s ease;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.8);
  background: linear-gradient(to right, #D86F71 50%, #AA4098 50%);
  vertical-align: middle;
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  padding-left: .3rem;
  padding-right: .3rem;
  @media (max-width: 650px) {
    margin-right: .75rem;
    margin-left: .75rem;
  }
}

.language p{
  margin: 0;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
  cursor: pointer;
}

.language:hover{
  border-color: #AA4098;
}

.title{
  display: flex;
  align-items: center;
  top: calc(15% + 0px);
  width: 100%;
  height: auto;
  text-align: center;
  font-size: 13px;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.title h1{
  width: 50%;
  margin: auto;
  color: white;
  line-height: 2.25rem;
  padding: 0;
  z-index: 10;
  @media (max-width: 650px) {
    font-size: 18px;
    line-height: 1.75rem;
    width: 100%;
    transition: all .3s ease-in-out;
  }
}

.photo{
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto; 
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;
  margin-top: -2.5%;
}

.photo img{
  max-width: 100%;
  height: auto;
}

.content{
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: .25px solid #fff;
  border-color: hsla(0, 0%, 100%, .2);
  width: 50%;
  height: auto;
  border-radius: 1rem;
  padding: 1.25rem;
  transition: all 0.3s ease;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.8);
  background-color: transparent;
  font-weight: 700;
  margin-left: auto;
  margin-right: auto;

@media (max-width: 650px) {
  width: 90%;
  padding: 1rem;
}
}

.input{
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 75%;
}

.lighting-vibration-button{
  /* animation: vibrate 0.8s ease infinite; */
  width: 100%;
}

/* .lighting-vibration-button:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 80%);
  opacity: 0;
  transition: opacity 0.3s ease;
} */

/* .lighting-vibration-button:hover:before {
  opacity: 1;
} */

/* @keyframes vibrate {
  0% {
    transform: translateX(0);
    opacity: 0.7;
  }
  25% {
    transform: translateX(-3px) translateY(3px);
    opacity: 0.9;
  }
  50% {
    transform: translateX(3px) translateY(3px);
    opacity: 1;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 50%);
  }
  75% {
    transform: translateX(-3px) translateY(-3px);
    opacity: 0.9;
  }
 100% {
    transform: translateX(3px) translateY(3px);
    opacity: 0.7;
  }
} */

.input p{
  margin: 0;
}

.textfield{
  display: flex;
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.cc{
  font-weight: 800;
  color: rgba(71, 85, 105, 1);
  padding: .5rem;
  background-color: white;
  border-color: #000;
  border-width: 1px;
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
  display: block;
  text-align: center;
  justify-content: center;
  justify-items: center;
  margin: auto;
  line-height: 1.25rem;
}

.textfield input{
  font-size: .875rem;
  line-height: 1.25rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  color: #AA4098;
  padding: 0.5rem 1.5rem;
  background-color: white;
  border-color: #000;
  border-width: 1px;
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
  width: 100%;
  display: block;
}

.textfield input:focus{
  border-color: #AA4098;
  border-width: 2px;
  outline: none;
}

.content button{
  margin-top: .75rem;
  margin-bottom: .75rem;
  transition: .3s;
  padding: .5rem;
  background-color: #7f28c4;
  border-radius: .25rem;
  cursor: pointer;
  font-size: 100%;
  text-align: center;
  align-items: flex-start;
  word-spacing: normal;
  letter-spacing: normal;
  word-spacing: normal;
  color: white;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  background-image: none;
  text-transform: none;
  text-align: center;
  border: 1px solid #7f28c4;
}

.content button:hover{
  border-width: 2.5px;
  border-color: white;
}

.terms{
  margin-top: .75rem;
  margin-bottom: .75rem;
  transition: .3s;
  padding: .5rem;
  cursor: pointer;
  font-size: 85%;
  text-align: center;
  align-items: flex-start;
  word-spacing: normal;
  letter-spacing: normal;
  word-spacing: normal;
  color: white;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  background-image: none;
  text-transform: none;
  text-align: center;
}

.terms a{
  text-decoration: none;
  color: white;
}

.terms a:hover{
  color: #AA4098;
}

.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 1;
  background: radial-gradient(circle at bottom right, #f39b51, #de3f81, #7f28c4);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.8);
  min-width: 20%;
  height: auto;
  @media (max-width: 650px) {
    width: 80%;
    padding: 1rem;
    font-size: 18px;
  }
}

.popup-content {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close:hover{
  color: #AA4098;
}

.otp-input-container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 5%;
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 0 5px;
  outline: none;
  font-weight: bold;
  @media (max-width: 650px) {
    width: 90%;
    padding: 1rem;
    font-size: 18px;
  }
}

/* Optional: Add focus style */
.otp-input:focus {
  border-color: #AA4098;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
}

.verify button{
  margin-top: .75rem;
  margin-bottom: .75rem;
  transition: .3s;
  padding: .5rem;
  background-color: #AA4098;
  border-radius: .25rem;
  cursor: pointer;
  font-size: 100%;
  text-align: center;
  align-items: flex-start;
  word-spacing: normal;
  letter-spacing: normal;
  word-spacing: normal;
  color: white;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  outline: none;
  border: none;
  background-image: none;
  text-transform: none;
  text-align: center;
  border: 1px solid #AA4098;
}

.verify button:hover{
  border-width: 2.5px;
  border-color: white;
}